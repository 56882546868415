$(document).ready(function () {

    // Check for click events on the navbar burger icon
    $(".navbar-burger").click(function () {

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        $(".navbar-burger").toggleClass("is-active");
        $(".navbar-menu").toggleClass("is-active");

    });
});

// Add active state to main menu item
$(function () {
    if ((location.pathname.split("/")[1]) !== "") {
        $('.navbar-end a[href^="/' + location.pathname.split("/")[1] + '"]').addClass('active-nav-item');
    }
});